"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { nanoid } from "nanoid";
import { setContentToState, collapse, expand } from "./helpers";
class Accordion {
  constructor(options) {
    __publicField(this, "id");
    __publicField(this, "rootRef");
    __publicField(this, "triggerRef");
    __publicField(this, "contentRef");
    __publicField(this, "isExpanded");
    __publicField(this, "isAnimating");
    __publicField(this, "initialHeight");
    __publicField(this, "eventListeners");
    __publicField(this, "onChangeSubscribers");
    __publicField(this, "onClickTrigger", (event) => {
      if (event && typeof event.preventDefault === "function") {
        event.preventDefault();
      }
      if (this.isAnimating)
        return;
      this.toggleAccordion();
    });
    __publicField(this, "onKeydownTrigger", (event) => {
      switch (event.key) {
        case "13":
        case "32":
          this.onClickTrigger(event);
          break;
        default:
          break;
      }
    });
    __publicField(this, "onAnimationComplete", () => {
      this.isAnimating = false;
      this.isExpanded = !this.isExpanded;
      this.onChangeSubscribers.forEach((cb) => {
        cb(this.isExpanded);
      });
    });
    __publicField(this, "onChange", (cb) => {
      this.onChangeSubscribers.push(cb);
      return () => {
        this.onChangeSubscribers = this.onChangeSubscribers.filter((item) => item !== cb);
      };
    });
    var _a;
    if (!options.rootRef)
      throw new Error("You must provide a rootRef");
    if (!options.triggerRef)
      throw new Error("You must provide a triggerRef");
    if (!options.contentRef)
      throw new Error("You must provide a contentRef");
    this.id = nanoid(10);
    this.rootRef = options.rootRef;
    this.triggerRef = options.triggerRef;
    this.contentRef = options.contentRef;
    this.isExpanded = options.isExpanded || false;
    this.initialHeight = (_a = options == null ? void 0 : options.initialHeight) != null ? _a : null;
    this.isAnimating = false;
    this.eventListeners = [];
    this.onChangeSubscribers = (options == null ? void 0 : options.onChangeSubscribers) || [];
    this.init();
  }
  init() {
    var _a, _b, _c, _d;
    setContentToState(this.contentRef, this.isExpanded, this.initialHeight ? `${this.initialHeight}px` : null);
    if (this.isExpanded) {
      this.rootRef.classList.add("is-expanded");
    }
    if (!Array.isArray(this.triggerRef)) {
      this.triggerRef = [this.triggerRef];
    }
    this.triggerRef.forEach((trigger) => {
      trigger.setAttribute("aria-expanded", this.isExpanded.toString());
      trigger.setAttribute("aria-controls", `sect-${this.id}`);
      trigger.setAttribute("id", `accordion-${this.id}`);
    });
    (_a = this.contentRef) == null ? void 0 : _a.setAttribute("aria-hidden", this.isExpanded.toString());
    (_b = this.contentRef) == null ? void 0 : _b.setAttribute("role", "region");
    (_c = this.contentRef) == null ? void 0 : _c.setAttribute("aria-labelledby", `accordion-${this.id}`);
    (_d = this.contentRef) == null ? void 0 : _d.setAttribute("id", `sect-${this.id}`);
    this.triggerRef.forEach((trigger) => {
      this.addListener(trigger, "click", this.onClickTrigger);
      this.addListener(trigger, "keydown", this.onKeydownTrigger);
    });
  }
  destroy() {
    this.removeAllListeners();
    this.triggerRef = null;
    this.contentRef = null;
  }
  addListener(elem, eventName, eventCallback) {
    if (!elem || typeof elem.addEventListener !== "function")
      return;
    elem.addEventListener(eventName, eventCallback);
    this.eventListeners.push({
      elem,
      eventName,
      eventCallback
    });
  }
  removeAllListeners() {
    this.eventListeners.forEach((listener) => {
      const { elem, eventName, eventCallback } = listener;
      elem.removeEventListener(eventName, eventCallback);
    });
    this.eventListeners = [];
  }
  toggleAccordion() {
    if (this.isExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }
  expand() {
    var _a;
    if (!this.triggerRef || !this.contentRef)
      return;
    this.isAnimating = true;
    this.triggerRef.forEach((trigger) => {
      trigger.setAttribute("aria-expanded", "true");
    });
    this.rootRef.classList.add("is-expanded");
    (_a = this.contentRef) == null ? void 0 : _a.setAttribute("aria-hidden", "false");
    expand({
      content: this.contentRef,
      onComplete: this.onAnimationComplete,
      maxHeight: this.initialHeight
    });
  }
  collapse() {
    var _a;
    if (!this.triggerRef || !this.contentRef)
      return;
    this.isAnimating = true;
    this.triggerRef.forEach((trigger) => {
      trigger.setAttribute("aria-expanded", "false");
    });
    this.rootRef.classList.remove("is-expanded");
    (_a = this.contentRef) == null ? void 0 : _a.setAttribute("aria-hidden", "true");
    collapse({
      content: this.contentRef,
      onComplete: this.onAnimationComplete,
      maxHeight: this.initialHeight
    });
  }
}
export default Accordion;
