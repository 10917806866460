"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import Accordion from "components/accordion/js/accordion";
export default class FooterComponent extends DcBaseComponent {
  static getNamespace() {
    return "footer-menu";
  }
  static getRequiredRefs() {
    return ["triggerRef", "contentRef"];
  }
  onInit() {
    this.initAccordion();
  }
  initAccordion() {
    this.accordion = new Accordion({
      rootRef: this.element,
      triggerRef: this.refs.triggerRef,
      contentRef: this.refs.contentRef
    });
  }
  destroy() {
    var _a;
    (_a = this.accordion) == null ? void 0 : _a.destroy();
  }
}
