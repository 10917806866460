"use strict";
import { gsap } from "gsap";
const DURATION = 0.3;
const EASING = "linear";
export const setContentToState = (content, isExpanded, initialHeight) => {
  content.style.overflow = "hidden";
  content.style.display = isExpanded || initialHeight ? "block" : "none";
  content.style.maxHeight = isExpanded ? "none" : initialHeight != null ? initialHeight : "none";
};
export const collapse = ({ content, onComplete, duration = DURATION, easing = EASING, maxHeight }) => {
  content.style.overflow = "hidden";
  content.style.maxHeight = maxHeight ? `${content.scrollHeight}px` : "none";
  gsap.fromTo(content, {
    maxHeight: `${content.scrollHeight}px`
  }, {
    maxHeight: maxHeight != null ? maxHeight : 0,
    duration,
    ease: easing,
    onComplete: () => {
      setContentToState(content, false);
      content.style.overflow = "hidden";
      content.style.display = maxHeight ? "block" : "none";
      content.style.maxHeight = maxHeight ? `${maxHeight}px` : "0";
      if (typeof onComplete === "function") {
        onComplete();
      }
    }
  });
};
export const expand = ({ content, onComplete, duration = DURATION, easing = EASING, maxHeight = 0 }) => {
  content.style.display = "block";
  gsap.fromTo(content, {
    maxHeight: maxHeight != null ? maxHeight : 0
  }, {
    maxHeight: `${content.scrollHeight}px`,
    duration,
    ease: easing,
    onComplete: () => {
      setContentToState(content, true);
      if (typeof onComplete === "function") {
        onComplete();
      }
    }
  });
};
